body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  /*overflow: hidden;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-scroll {
  display: inline-block;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  margin-right: 1rem;
  height: 5rem;
  width: 35%;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  color: #ffffff;
  background-color: #343a40;
  border: 1px solid #526c84;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  background-color: #343a40;
  background-clip: padding-box;
  border: 1px solid #526c84;
  border-radius: 0rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.card {
  background-color: #2b2b2b;
  border-radius: 0px;
  border: 0px solid #343a40;
  font-size: .85rem;
}

.card-header {
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0,0,0,.03);
  border-bottom: 1px solid rgb(82, 108, 132);
  border-top: 1px solid #526c84;
  background-color: #2b2b2b;
}

.btn-primary {
  color: #fff;
  background-color: #526c84;
  border-color: #526c84;
  border-top-color: rgb(82, 108, 132);
  border-right-color: rgb(82, 108, 132);
  border-bottom-color: rgb(82, 108, 132);
  border-left-color: rgb(82, 108, 132);
}

.bg-dark{
  background-color:#2b2b2b!important;
}

/* Side Menu */

.pro-sidebar {
  height: calc(100vh - 4.5rem);
}

.pro-icon {
  animation: none!important;
}

.pro-sidebar-inner {
  color:#ffffff;
  background: #343a40;
}

.pro-sidebar > .pro-sidebar-content {
  flex-grow: 1;
}

.popper-inner {
  width: auto!important;
}

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item {
  width: auto!important;
  max-width: none;
}

hr {
  border-top: 1px solid #3a3a3a;
}

.popper-inner ul {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  list-style-type: none!important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
  display:none;
}

.modalDownload {
  padding: 0px!important;
  background: none;
  border: none;
  float: right;
  line-height: 1.2;
  width: 1em;
  height: 1em;

}

.modalDownload svg:hover{
  background:none;
  color: #526c84;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition-property: color, background-color, border-color, box-shadow;
  transition-duration: 0.15s, 0.15s, 0.15s, 0.15s;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
  transition-delay: 0s, 0s, 0s, 0s;
}

.modalDownload:hover {
  background:none;
}

.spatialEvents > .pro-sidebar > .pro-sidebar-content {
  flex-grow: 0;
}
.spatialEvents ul {
  display: grid;
  grid-template-columns: repeat(1, 1fr)!important;
  list-style-type: none!important; 
}

li {
  list-style-type: none!important;
}


.btn {
  border-radius: 0px;
}

.react-datepicker-popper {
  left: -5.1rem!important;
  z-index: 9999!important;
}

.react-datepicker__input-container input {
  background-color: #343a40;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  margin-left: -.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  background-color: #343a40;
  background-clip: padding-box;
  border: 1px solid #526c84;
  border-radius: 0rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.iconToggled span {
  background-color: darkred;
}

.subIconToggled span {
  background-color: #526c84;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: .5rem;
  top: 1.125rem;
}

.pro-icon-wrapper-sub {
  font-size: 11px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #526c84;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #ffffff;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #343a40;
  padding: 2.5em 1.5em 0;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #526c84;
    border-top-color: rgb(82, 108, 132);
    border-right-color: rgb(82, 108, 132);
    border-bottom-color: rgb(82, 108, 132);
    border-left-color: rgb(82, 108, 132);
    background-color: #526c84;
}

a.bm-item.menu-item {
  color: #ffffff;
}

a.bm-item.menu-item--small {
  color: #ffffff;
}

.fixedCornerChart {
  background-color: #343a40;
  width: calc(33.3333vw - 1.5rem);
  z-index: 999;
  position: absolute;
  color: #ffffff;
  margin-top: 15px;
  pointer-events: visible;
}

.fixedCornerChart h2 {
  font-size: 1rem;
  padding-top: .75rem;
  padding-left: .75rem;
  padding-right: .75rem;

}

.fixedExpandedCornerChart {
  width: calc(99.9999vw - 3rem)!important;
}

.scaleContainer {
  border-radius: .2rem;
  margin: auto;
  margin-bottom: 2rem;
  position: sticky;
  width: 50%;
  z-index: 0;
  height: auto;
  opacity: .6;
}

.dragGrid {
  pointer-events: none;
  padding-left: 6.5rem;
  padding-right: 5rem;
}

.dropCellPassive {
  pointer-events: none;
  min-height: calc(33.33333vh - 1.9rem);
  min-width: calc(33.3333vw - 1.7rem);
  z-index: 1;
  position: relative;
  color: #ffffff;
  margin: 0px;
}

.dropCellActive {
  pointer-events: visible;
  min-height: calc(33.33333vh - 1.9rem);
  min-width: calc(33.3333vw - 1.7rem);
  z-index: 1;
  position: relative;
  color: #ffffff;
  margin: 0px;
}

.container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}

.navSearch {
  width: 100%;
}

.navForm {
  min-width: 11em;
  margin-left: 1em;
}

.electionToggleLabel {

  color: #fff;
  border-color: #526c84;
  border-top-color: rgb(82, 108, 132);
  border-right-color: rgb(82, 108, 132);
  border-bottom-color: rgb(82, 108, 132);
  border-left-color: rgb(82, 108, 132);
  margin-right: .5em;
  margin-bottom: 1px!important;

}

.colorScale {
  border-radius: .2rem;
  opacity: 1;
  border-color: rgb(82, 108, 132);
  width: auto;
  display: flex;
  justify-content: space-between;
  padding: .075rem .5rem;
  background-image: linear-gradient(to right,#000000,#18001B,#300036,#470052,#5F006D,#770088,#7A008B,#7E008F,#810092,#850096,#880099,#6D009C,#5200A0,#3600A3,#1B00A7,#0000AA,#0000B4,#0000BE,#0000C9,#0000D3,#0000DD,#0018DD,#0030DD,#0047DD,#005FDD,#0077DD,#007EDD,#0085DD,#008BDD,#0092DD,#0099DD,#009CD3,#00A0C9,#00A3BE,#00A7B4,#00AAAA,#00AAA3,#00AA9C,#00AA96,#00AA8F,#00AA88,#00A76D,#00A352,#00A036,#009C1B,#009900,#00A000,#00A700,#00AD00,#00B400,#00BB00,#00C200,#00C900,#00CF00,#00D600,#00DD00,#00E400,#00EB00,#00F100,#00F800,#00FF00,#25FF00,#4BFF00,#70FF00,#96FF00,#BBFF00,#C5FC00,#CFF800,#DAF500,#E4F100,#EEEE00,#F1E700,#F5E000,#F8DA00,#FCD300,#FFCC00,#FFC200,#FFB800,#FFAD00,#FFA300,#FF9900,#FF7A00,#FF5C00,#FF3D00,#FF1F00,#FF0000,#F80000,#F10000,#EB0000,#E40000,#DD0000,#DA0000,#D60000,#D30000,#CF0000,#CC0000,#CC2929,#CC5252,#CC7A7A,#CCA3A3,#CCCCCC); 
}

.scaleText{
  color:rgba(255, 255, 255, 1);
  opacity: 1;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  font-size: .9rem;
  line-height: 1.5;
  font-weight: 600;
}

.osmAttrib{
  opacity: .925;
  float: right;
  padding: .2rem .5rem;
  width: auto;
  font-family: Roboto, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  font-size: 10px;
  font-weight: 400;
  background-color: #343a40;
  color: white;
}

.aboutModalBody {
  padding: 0rem;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 3rem;
  width: 2rem;
  background-image: none;
  margin-bottom: 2rem;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
  background-color: #343a40;
  color: white;
  border-color: grey;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%526c84' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
 }
 
 .carousel-control-next-icon {
   background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%526c84' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
 }

 .navbar-brand {
  display: inline-block;
  /* padding-top: .3125rem; */
  /* padding-bottom: .3125rem; */
  margin-right: 1rem;
  font-size: 1rem;
  line-height: inherit;
  white-space: nowrap;
}



a.navbar-brand {
    display: flex;
}

.navbar-nav {
  width: 100%;
}

.navbar-nav > h2 {
  text-transform: capitalize;
}
.changeDate {
  display: inline-flex;
  margin-left: auto; 
  margin-right: 0;
}
.exampleDropdown {
  font-size: 1.25rem;
}

.exampleDropdown > a {
  color: #fff!important;
}

@media screen and (min-width: 1421px) {
  .largeNav {
    display: none;
  }
  .smallNav {
    display: none;
  }
  .typeAhead {
    margin-right: 0rem;
    width: 100%;
  }
  .navbar {
    padding: .75rem 1rem;
  }
}

@media screen and (min-width: 901px) and (max-width: 1420px) {
  .largeNav {
    display: none;
  }
  .smallNav {
    display: none;
  }
  .typeAhead {
    margin-right: 0rem;
    width: 100%;
  }
  .navbar {
    padding: .5rem .5rem .5rem 1.05rem;
  }
}

@media screen and (max-width: 900px) {
  .largeNav {
    display: none;
  }
  .typeAhead {
    margin-right: 0rem;
    width: 100%;
  }

  .medNav {
    display: none;
  }

  .navbar {
    padding: .5rem;
  }
}

.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #f3f4f4;
  background-color: #526c84;
  border: 1px solid #454d55;
}

.card-body {
  color: white;
}

.card-header {
  color: white;
  border-color: grey;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
    flex-grow: 1;
    flex-shrink: 1;
    height: 35px;
    line-height: 35px;
    text-indent: 3pt;
}

span.triggerWord:hover {
  color: orange!important;
}

@media (min-width: 992px) {
  .container, .container-lg, .container-md, .container-sm {
    max-width: none;
  }
}

@media (min-width: 576px) {
  .form-inline .custom-select, .form-inline .input-group {
      width: 100%;
  }
}